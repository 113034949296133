import Link from 'next/link';
import { ICell } from '../Cell';
import { CONTINUATION } from '@/shared/components/fields/ExtendedTask';

export const ExtendedTaskCell = ({ value }: ICell) => {
	if (typeof value !== 'object' && !value?.continuation_type) {
		return <p className="text-xs text-gray-300">Invalid data</p>;
	}

	const continuationType =
		value?.continuation_type === CONTINUATION[CONTINUATION.INTERNAL] ? 'Continuation of own consultant' : 'Continuation competitor';
	const link = `/projects/${value?.extended_task_uid}`;

	if (!value) {
		return <p className="text-xs font-medium">Not defined</p>;
	}

	return (
		<div className="flex items-center gap-2 text-xs font-medium">
			<p>{continuationType} - </p>
			<Link className="text-blue-500 underline" href={link}>
				Task
			</Link>
		</div>
	);
};
