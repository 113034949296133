import { Popover } from 'antd';
import { ICell } from '../Cell';

export const TextAreaCell = (props: ICell) => {
	if (typeof props.value !== 'string') {
		return <p className="text-xs text-gray-300">Invalid data</p>;
	}
	const teaser = props.value?.substring(0, 80);
	return <Popover content={props.value}>{teaser}</Popover>;
};
