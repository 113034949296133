import { useEffect, useState } from 'react';

export const useLoading = (predicate: boolean, timeout?: number) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const delay = timeout || 3000;

	useEffect(() => {
		if (predicate) {
			setIsLoading(false);
		}
		const timeout = setTimeout(() => {
			setIsLoading(false);
		}, delay);
		return () => {
			clearTimeout(timeout);
		};
	}, [predicate]);

	return isLoading;
};
