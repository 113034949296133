import { Skeleton } from '@/shared/components/skeletons/Skeleton';
import { ImageWithFallback } from '@/utils/ImageWithFallback';
import { useUser } from 'hooks/cvpartner/useUser';
import { ICell } from '../../Cell';

export const ProfileImageCell = (props: ICell) => {
	const cvpId = props.record?.cvp_id;
	const { user, isLoading, error } = useUser(props.record?.type === 'INTERNAL' ? cvpId : null);

	if ((error || !user) && !isLoading) {
		return <span className="text-gray-200">No profile image</span>;
	}

	if (isLoading) {
		return <Skeleton className="w-12 h-12 rounded-full" />;
	}

	return (
		<div className="w-full h-full flex justify-center">
			<div style={user?.image?.thumb?.url ? { overflow: 'hidden' } : {}} className="relative rounded-full h-12 w-12">
				<ImageWithFallback src={user.image.thumb.url} objectFit="cover" className="h-10 max-w-[100px] w-full object-cover" fill />
			</div>
		</div>
	);
};
