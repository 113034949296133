import { Skeleton } from '@/shared/components/skeletons/Skeleton';
import { fetcher } from '@/utils/api';
import { Popover } from 'antd';
import { useUser } from 'hooks/cvpartner/useUser';
import useSWR from 'swr';
import { ICell } from '../../Cell';

export const TagsCell = (props: ICell) => {
	const cvpId = props.record?.cvp_id;
	const { user } = useUser(props.record?.type === 'INTERNAL' ? cvpId : null);

	const userId = user?.id;
	const cvId = user?.default_cv_id;

	const {
		data: tags,
		isLoading,
		error,
	} = useSWR<string[]>(userId && cvId ? `/cvpartners/cvs/${cvId}/${userId}/tags` : null, fetcher, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
	});

	if ((error || !tags?.length) && !isLoading) {
		return <p className="text-gray-200">No tags</p>;
	}

	if (isLoading) {
		return <Skeleton className="h-4 w-12" />;
	}

	const displayedTags = [];
	const remainingTags = [];

	for (let i = 0; i < tags.length; i++) {
		if (i < 3) {
			displayedTags.push(tags[i]);
		} else {
			remainingTags.push(tags[i]);
		}
	}

	const popoverContent = (
		<div className="max-h-40 max-w-sm flex-col flex-wrap overflow-y-auto">
			{remainingTags.map((tag) => (
				<div key={tag} className={'bg-custom-gray text-custom-gray-dark mb-2 w-fit rounded-md px-2 py-1 text-xs'}>
					<p>{tag}</p>
				</div>
			))}
		</div>
	);

	return (
		<div className="flex w-48 flex-wrap gap-1">
			{displayedTags.map((tag) => (
				<Popover key={tag} content={tag} placement="top">
					<div className={'bg-custom-gray text-custom-gray-dark w-40 rounded-md px-2 py-1 text-xs'}>
						<p style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{tag}</p>
					</div>
				</Popover>
			))}

			{remainingTags.length > 0 && (
				<Popover content={popoverContent} title="Additional Tags">
					<div className={'bg-custom-gray text-custom-gray-dark w-10 rounded-md px-2 py-1 text-xs'}>
						<p>+{remainingTags.length}</p>
					</div>
				</Popover>
			)}
		</div>
	);
};
