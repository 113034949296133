import { DropdownGeneric } from '@/shared/components/fields';
import { ICell } from '../Cell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCloudDownload } from '@fortawesome/pro-regular-svg-icons';
import { IFile } from '@/features/storage/types';
import { downloadFile } from '@/utils/api/storage';

export const AttachmentsCell = ({ value }: ICell) => {
	if (Array.isArray(value) || typeof value?.[0]?.filename !== 'string') {
		return <p className="text-xs text-gray-300">Invalid data</p>;
	}

	if (!value || !value.length) {
		return 'No attachments';
	}

	return (
		<div className="w-fit">
			<DropdownGeneric
				collection={value as IFile[]}
				renderItem={({ item }) => (
					<button onClick={() => downloadFile(item.uid)} className="flex items-center gap-2 p-2 rounded-md hover:bg-neutral-100 m-1">
						<FontAwesomeIcon icon={faCloudDownload} />
						<p>{item.filename}</p>
					</button>
				)}
				button={
					<div className="flex items-center gap-2">
						<p>See attachments</p>
						<span>
							<FontAwesomeIcon icon={faChevronDown} />
						</span>
					</div>
				}
			/>
		</div>
	);
};
