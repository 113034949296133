import { selectedSpaceAtom } from '@/atoms/spaces';
import { formatValue } from '@/shared/components/inputs';
import { useRecoilValue } from 'recoil';
import { ICell } from '../Cell';
import { formatTypeValues } from '../../../shared/utils/helper';

export const MoneyCell = ({ value }: ICell) => {
	const space = useRecoilValue(selectedSpaceAtom);
	let { currency, locale } = space;
	currency ??= 'EUR';
	locale ??= 'en-US';

	if (typeof value !== 'number' || typeof value?.[0] !== 'number') {
		return <p className="text-xs text-gray-300">Invalid data</p>;
	}

	if (Array.isArray(value)) {
		return value.map((v) => formatValue(formatTypeValues({ value: v, currency, locale }, 'money'))).join(' - ');
	}
	return formatValue(formatTypeValues({ value, currency, locale }, 'money'));
};
