import { selectedSpaceAtom } from '@/atoms/spaces';
import { selectedTask } from '@/atoms/tasks';
import Avatar from '@/components/avatar';
import { PERIOD_ELEMENT_HEIGHT, SIDE_BAR_WIDTH } from '@/components/timeline/helpers/constants';
import { Icon } from '@/features/icons/components/IconLoader';
import { IUser } from '@/features/people/types';
import { useLoading } from '@/features/rfq/hooks/useLoading';
import { Skeleton } from '@/shared/components/skeletons/Skeleton';
import { IDate } from '@/types/glossary';
import { ImageWithFallback, ORGANISATION_AVATERS } from '@/utils/ImageWithFallback';
import { cn, parseName } from '@/utils/helpers';
import { faEdit, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { faRotate, faStars, faUserClock, faUserXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs, { Dayjs } from 'dayjs';
import { IGlossaryValues, useGlossary } from 'hooks/useGlossary';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { candidateFlowAtom, selectedTalentAtom } from '../utils/atoms';

interface IAllocationElement {
	allocation: {
		hired: number;
		potential: number;
		extension: number;
	};
}

export const AllocationPill = ({ allocation, status }: { allocation: number; status: string }) => {
	let backgroundColor = '#E5EBF2';
	let color = '#79879B';
	let icon = faRotate;
	switch (status) {
		case 'hired': {
			backgroundColor = '#0ADE90';
			color = '#ffffff';
			icon = faStars;
			break;
		}
		case 'potential': {
			backgroundColor = '#60a5fa';
			color = '#ffffff';
			icon = faUserClock;
			break;
		}
	}

	if (allocation === 0) {
		return null;
	}

	return (
		<div style={{ backgroundColor, color }} className={'flex w-20 items-center justify-center gap-2 rounded-md px-2 py-1 text-xs'}>
			<FontAwesomeIcon icon={icon} />
			<span>{allocation}%</span>
		</div>
	);
};

export const AllocationElement = ({ allocation }: IAllocationElement) => {
	const totalAllocation = allocation.hired + allocation.potential + allocation.extension;
	const containerRef = useRef<HTMLDivElement>(null);
	const width = containerRef.current?.offsetWidth ?? 0;
	let bgColor = '#f87171';
	if (allocation.extension > 0) {
		bgColor = '#E5EBF2';
	}
	if (allocation.potential > 0) {
		bgColor = '#60a5fa';
	}
	if (allocation.hired > 0) {
		bgColor = '#0ADE90';
	}

	const prioritizedAllocation = Object.entries(allocation).reduce((acc, [key, value]) => {
		let temp = { ...acc };
		if (value > 0) {
			if (key === 'hired') {
				temp[key] = value;
			} else if (Object.keys(temp)[0] !== 'hired' && key === 'potential') {
				temp[key] = value;
			} else if (!Object.keys(temp)[0]) {
				temp[key] = value;
			}
		}
		acc = temp;
		return acc;
	}, {});

	const [key, value] = Object.entries(prioritizedAllocation)[0] ?? [];

	return (
		<div ref={containerRef} className="pointer-events-auto flex h-full cursor-default flex-col justify-end overflow-hidden border-r">
			<div style={{ backgroundColor: bgColor }} className={'relative flex h-full w-full items-center justify-center gap-2'}>
				{totalAllocation === 0 ? (
					<div className={'flex w-20 items-center justify-center gap-2 rounded-md bg-red-400 px-2 py-1 text-xs text-white'}>
						<FontAwesomeIcon icon={faUserXmark} />
						<span>0%</span>
					</div>
				) : width < 225 ? (
					<AllocationPill status={key} allocation={value ? Number(value) : 0} />
				) : (
					Object.entries(allocation).map(([key, value]) => {
						const Pill = <AllocationPill key={key} status={key} allocation={value} />;
						if (width < 150) {
							if (key === 'hired' && value > 0) {
								return Pill;
							} else if (key === 'potential' && value > 0) {
								return Pill;
							} else if (key === 'extension' && value > 0) {
								return Pill;
							}
							return null;
						} else {
							return Pill;
						}
					})
				)}
			</div>
		</div>
	);
};

export const UserTitle = ({ user, talent }: { user: IUser; talent?: IGlossaryValues }) => {
	const setSelectedTalent = useSetRecoilState(selectedTalentAtom);

	return (
		<div className="group flex w-full items-center justify-between gap-2">
			<div className="flex flex-1 items-center gap-2">
				<Avatar email={user.email} size={32} border={false} />
				<p className="text-sm font-medium text-gray-500">{parseName(user)}</p>
			</div>
			{!!talent && (
				<button className="scale-0 transition-all group-hover:scale-100" onClick={() => setSelectedTalent({ talent })}>
					<FontAwesomeIcon icon={faEdit} />
				</button>
			)}
		</div>
	);
};

export const TaskTitle = ({ task, sourceTask, talent }: { task: Record<string, any>; sourceTask: Record<string, any>; talent?: IGlossaryValues }) => {
	const { values } = useGlossary(task, task?._owner?.glossary_uid) as { values: IGlossaryValues };
	const { values: _talent } = useGlossary(talent, task?._owner?.glossary_uid) as { values: IGlossaryValues };
	const setSelectedTalent = useSetRecoilState(selectedTalentAtom);
	const setSelectedTask = useSetRecoilState(selectedTask);
	const setCandidateFlow = useSetRecoilState(candidateFlowAtom);
	const space = useRecoilValue(selectedSpaceAtom);
	const router = useRouter();
	const isOwner = space.uid === talent?.task?._owner?.uid;
	const taskUID = isOwner ? sourceTask.uid : task.uid;
	const taskName = !isEmpty(values?._name) ? values._name : 'No task name found';
	const isLoadingName = useLoading(!isEmpty(values?._name), 10000);

	const navigateToTask = () => {
		router.push(`/projects/${taskUID}`);
	};

	return (
		<div className="group flex w-full items-center justify-between gap-2">
			<div className="flex flex-1 items-center gap-2">
				<div className="w-fit">
					<ImageWithFallback
						src={ORGANISATION_AVATERS(values._customer)}
						width={0}
						height={0}
						alt="Customer"
						className="max-h-[32px] w-auto min-w-[32px] max-w-[60px]"
					/>
				</div>
				<button onClick={navigateToTask} className="text-xs font-medium hover:underline">
					<p title={taskName} className="line-clamp-1">
						{isLoadingName ? <Skeleton className="h-4 w-24" /> : taskName}
					</p>
				</button>
			</div>
			<div className="flex items-center gap-2">
				<button
					className="scale-0 transition-all group-hover:scale-100"
					onClick={(e) => {
						e.stopPropagation();
						setSelectedTask(task);
						setCandidateFlow({ task: taskUID, open: true });
					}}
				>
					<FontAwesomeIcon icon={faUserPlus} />
				</button>
				{!!talent && (
					<button
						className="scale-0 transition-all group-hover:scale-100"
						onClick={(e) => {
							e.stopPropagation();
							setSelectedTalent({ talent });
						}}
					>
						<FontAwesomeIcon icon={faEdit} />
					</button>
				)}
			</div>
		</div>
	);
};

export const PeriodElement = (props: { start: Dayjs | null; end: Dayjs | null; top?: number | null; period: IDate; talent: IGlossaryValues }) => {
	const { start, talent, period } = props;
	let title, icon;
	if (!start) {
		return <ElementInvalidDates talent={talent} />;
	}
	switch (period?.data?.status) {
		case 'hired': {
			icon = 'fasStars';
			break;
		}
		case 'potential': {
			icon = 'fasUserClock';
			break;
		}
		case 'extension': {
			title = `Option to extend ${period?.data?.allocation ? `(${period?.data?.allocation}%)` : ''}`;
			icon = 'fasRotate';
			break;
		}
	}
	return <Period {...props} title={title} icon={icon} />;
};

const Period = ({
	start,
	end,
	top = 0,
	period,
	title,
	icon,
	talent,
}: {
	start: Dayjs;
	end: Dayjs;
	top?: number | null;
	title: string | null;
	icon: string;
	period: IDate;
	talent: IGlossaryValues;
}) => {
	const [selectedTalent, setSelectedTalent] = useRecoilState(selectedTalentAtom);
	const elementRef = useRef<HTMLDivElement>(null);
	const isSelected = selectedTalent?.period?.key === period.key;
	const isHired = period?.data?.status === 'hired';
	const isPotential = period?.data?.status === 'potential';
	const isExtension = period?.data?.status === 'extension';
	const isFuture = start?.isAfter(dayjs());
	let backgroundStyle;

	if (isExtension && isFuture) {
		backgroundStyle = { background: 'repeating-linear-gradient(-55deg, #f3f4f6, #f3f4f6 4px, #f9fafb 4px, #f9fafb 10px)' };
	} else if (isPotential && isFuture) {
		backgroundStyle = { background: 'repeating-linear-gradient(-55deg, #dbeafe, #dbeafe 4px, #eff6ff 4px, #eff6ff 10px)' };
	} else if (isHired && isFuture) {
		backgroundStyle = { background: 'repeating-linear-gradient(-55deg, #0ADE9033, #0ADE9033 4px, #0ADE901A 4px, #0ADE901A 10px)' };
	}

	return (
		<div
			ref={elementRef}
			style={{ top, maxHeight: PERIOD_ELEMENT_HEIGHT }}
			className={cn(
				'pointer-events-auto absolute w-full transition-all ease-in-out hover:z-40 hover:scale-[1.01] hover:shadow-lg',
				isSelected && 'z-40 scale-[1.01] shadow-lg'
			)}
		>
			<div
				onClick={() => setSelectedTalent({ talent, period })}
				style={backgroundStyle}
				className={cn(
					'z-20 flex flex-grow cursor-pointer select-none items-center justify-between rounded-md border bg-gray-100 px-2 py-1',
					isPotential && 'border-blue-400 bg-blue-100',
					isHired && 'border-[#0ADE90] bg-[#0ADE90]/10'
				)}
			>
				<div className="relative flex-grow">
					<div style={{ left: SIDE_BAR_WIDTH + 64 }} className="sticky flex w-fit items-center gap-2">
						<Icon className={cn(isPotential && 'text-blue-400', isHired && 'text-[#0ADE90]')} icon={icon} />
						<div>
							<p className="line-clamp-1 text-xs font-medium">
								{title
									? title
									: `${/*formattedPrice ? `${formattedPrice} - ` : ''*/ ''} ${
											period?.data?.allocation ? period?.data?.allocation : 0
									  }%`}
							</p>
							<p className="line-clamp-1 text-[8px] text-gray-500">
								{start ? start.format('DD MMM YY') : ''} - {end ? end.format('DD MMM YY') : 'Future'}
							</p>
						</div>
					</div>
				</div>
				{elementRef.current?.offsetWidth > 150 && ['hired', 'potential'].includes(period?.data?.status) && (
					<div
						className={cn(
							'rounded-md px-2 py-1 text-[10px]',
							isPotential && 'bg-blue-400 text-white',
							isHired && 'bg-[#0ADE90] text-white'
						)}
					>
						<span>{isHired ? 'Hired' : 'Potential booking'}</span>
					</div>
				)}
			</div>
		</div>
	);
};

const ElementInvalidDates = ({ talent }: { talent: IGlossaryValues }) => {
	const setSelectedTalent = useSetRecoilState(selectedTalentAtom);

	return (
		<div className="pointer-events-auto flex h-full items-center">
			<div
				onClick={() => setSelectedTalent({ talent })}
				className="relative z-20 flex-grow cursor-pointer border-y border-dashed border-gray-500 bg-gray-100 px-2 py-1 opacity-30"
			>
				<div style={{ left: SIDE_BAR_WIDTH + 56 }} className="sticky w-fit select-none truncate text-sm font-medium">
					No start date selected
				</div>
			</div>
		</div>
	);
};
