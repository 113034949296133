import { Popover } from 'antd';
import { ImageWithFallback, ORGANISATION_AVATERS } from '@/utils/ImageWithFallback';
import { ICell } from '../../Cell';

export const CompanyCell = (props: ICell) => {
	const space = props.record?.space;
	return (
		<Popover placement={'topLeft'} overlayStyle={{ pointerEvents: 'none' }} content={<div className="text-xs font-normal">{space.name}</div>}>
			<>
				<ImageWithFallback src={ORGANISATION_AVATERS(space?.uid)} fallback={space?.name} width={75} height={75} />
			</>
		</Popover>
	);
};
