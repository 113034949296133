import { fetcher } from '@/utils/api';
import useSWRImmutable from 'swr/immutable';
import { ICell } from '../../Cell';

const NAME_MAP = {
	tripletex_customer: 'name',
	tripletex_project_manager: 'displayname',
	tripletex_delivery_address: 'displayname',
	tripletex_department: 'name',
	tripletex_main_project: 'name',
	tripletex_vat_type: 'displayname',
	tripletex_currency: 'displayname',
	tripletex_contact: 'displayname',
	tripletex_attention: 'displayname',
};

export const TripletexResourceCell = ({ value, column }: ICell) => {
	const { data: resource } = useSWRImmutable(value?.url ? `/tripletex/resources/url?url=${value?.url}` : null, fetcher);
	const displayName = resource?.value?.[NAME_MAP[column.datatype]];

	if (!displayName) {
		return <p className="text-gray-300">Not defined</p>;
	}

	return displayName;
};
