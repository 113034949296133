import { IDate } from '@/types/glossary';
import { IGlossaryValues } from 'hooks/useGlossary';
import { atom } from 'recoil';

export const selectedTalentAtom = atom<{ talent: IGlossaryValues; period?: IDate }>({
	key: 'selectedTalent',
	default: null,
});

export const candidateFlowAtom = atom<{ task: string; open: boolean }>({
	key: 'candidateFlow',
	default: {
		task: null,
		open: false,
	},
});
