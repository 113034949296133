import { IOption } from '@/features/datasources/types';
import { RenderSelectionDot } from '@/features/templates/components/RenderSelectionDot';
import { DropdownGeneric } from '@/shared/components/fields';
import { DropdownItem } from '@/shared/components/items/DropdownItem';
import { OptionPill } from '@/shared/components/items/StatusPill';
import { fetcher } from '@/utils/api';
import { updateNodeContent } from '@/utils/api/nodes';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import useSWR from 'swr';
import { ICell } from '../Cell';

export const SelectCell = ({ value, record, column, disabled }: ICell & { disabled?: boolean }) => {
	const [selected, setSelected] = useState<IOption[]>([]);
	const { data } = useSWR<IOption[]>(column?.field ? `/datasources/options?field=${column.field}` : null, fetcher, {
		revalidateOnFocus: false,
		revalidateIfStale: false,
		refreshInterval: 0,
	});

	useEffect(() => {
		fetchOptions();
	}, [data, value]);

	const fetchOptions = async () => {
		if (!data || !value) return;
		const uids = Array.isArray(value) ? value.map((uid) => `${uid}`).join(',') : `${value}`;
		const selected = data.filter((option) => uids.includes(option.uid));
		setSelected(selected);
	};

	const onToggle = (uid: string) => {
		if (isSelected(uid)) {
			const filtered = selected.filter((item) => item.uid !== uid);
			setSelected(filtered);
			handleMutation(filtered);
		} else {
			const item = data.find((item) => item.uid === uid);
			const value = column.datatype === 'select' ? [item] : [...selected, item];
			setSelected(value);
			handleMutation(value);
		}
	};

	const handleMutation = async (options: IOption[]) => {
		if (!record?.uid) {
			toast.error('Failed to update cell');
			return;
		}
		const value = column.datatype === 'select' ? options?.[0]?.uid : options.map((o) => o.uid);
		await updateNodeContent(record.uid, {
			[column.dataindex]: value,
		});
	};

	const isSelected = (uid: string) => {
		return selected.some((item) => item.uid === uid);
	};

	return (
		<DropdownGeneric
			disabled={disabled}
			collection={data}
			renderItem={({ item }) => (
				<DropdownItem
					title={
						<div className="flex items-center gap-2 text-xs">
							<RenderSelectionDot color={item?.color} selected={isSelected(item.uid)} /> {item?.text}
						</div>
					}
					onClick={(e) => {
						e.stopPropagation();
						onToggle(item.uid);
					}}
				/>
			)}
			button={
				<div onClick={(e) => e.stopPropagation()}>
					{!selected.length ? (
						<p className="text-xs text-gray-300">{disabled ? '' : 'Select option'}</p>
					) : (
						<div className="space-y-1">
							{selected.map((option) => (
								<OptionPill key={option.uid} option={option} />
							))}
						</div>
					)}
				</div>
			}
		/>
	);
};
