import { ICell } from '../../Cell';

export const UserTypeCell = ({ value }: ICell) => {
	return (
		<div
			style={{ background: value === 'INTERNAL' ? 'rgb(134 239 172)' : 'rgb(252, 165, 165)' }}
			className={`cursor-pointer capitalize px-2 red py-1 w-fit rounded-md text-center font-medium text-white text-[12px]`}
		>
			{value.replace('_', ' ')}
		</div>
	);
};
