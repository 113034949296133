import { fetcher } from '@/utils/api';
import { useEffect, useState } from 'react';
import { ICell } from '../../Cell';

export const TripletexParticipantsCell = ({ value, column }: ICell) => {
	const [participants, setParticipants] = useState([]);

	useEffect(() => {
		fetchParticipants();
	}, []);

	const fetchParticipants = async () => {
		const data = [];
		await Promise.all(
			(value || []).map(async (p) => {
				const res = await fetcher(`/tripletex/resources/url?url=${p.url}`);
				const emp = await fetcher(`/tripletex/employees/${res.value.employee.id}`);
				data.push(emp.value);
			})
		);
		setParticipants(data);
	};
	return participants.map((p) => p.displayname).join(', ');
};
