import { BasicButton } from '@/components/buttons/BasicButton';
import Modal from '@/components/modal/Modal';
import SuppliersSelector from '@/components/projects/components/suppliers/selector';
import { suppliersCollection, taskSuppliersCollection } from '@/utils/api/collections';
import { useEffect, useState } from 'react';
import { ICell } from '../Cell';

export const SupplierCell = (props: ICell) => {
	const [selectedSuppliers, setSelectedSuppliers] = useState<any[]>([]);
	const [showModal, setShowModal] = useState<boolean>(false);

	useEffect(() => {
		fetchSelectedCollection();
	}, [props.record]);

	const fetchSelectedCollection = async () => {
		const taskSuppliers = await taskSuppliersCollection(props.record.uid);
		const suppliers = await suppliersCollection();
		setSelectedSuppliers(
			taskSuppliers.map((s) => {
				const contactPersons = suppliers.find(({ uid }) => uid === s.uid)?.contacts;
				return {
					...s,
					selected_contacts: contactPersons?.filter((cp) => s.contact_persons?.includes(cp.uid)),
				};
			})
		);
	};

	return (
		<>
			<Modal open={showModal}>
				<>
					<div className="min-w-[100vh]">
						<SuppliersSelector selected={selectedSuppliers} readOnly />
					</div>
					<BasicButton onClick={() => setShowModal(false)} className="w-full">
						<p>Close</p>
					</BasicButton>
				</>
			</Modal>
			<BasicButton
				onClick={(e) => {
					e.stopPropagation();
					setShowModal(true);
				}}
			>
				View suppliers
			</BasicButton>
		</>
	);
};
