import { ProgressBar } from '@/shared/components/fields';
import { ICell } from '../Cell';

export const ProgressCell = ({ value, record }: ICell) => {
	if (typeof value !== 'object' && !value?.status) {
		return <p className="text-xs text-gray-300">Invalid data</p>;
	}
	return (
		<div className="max-w-xs">
			<ProgressBar task={record.uid} value={value} readOnly />
		</div>
	);
};
