import { useEffect, useState } from 'react';
import { ICell } from '../../Cell';
import { RenderNA } from '../../../../shared/components/items/RenderNA';
import { fetcher } from '@/utils/api';

export const TemplateCell = ({ value }: ICell) => {
	if (typeof value !== 'string') {
		return <p className="text-xs text-gray-300">Invalid data</p>;
	}

	const [template, setTemplate] = useState<any>();

	useEffect(() => {
		fetchCVPTemplates();
	}, []);

	const fetchCVPTemplates = async () => {
		const templates = await fetcher('/cvpartners/templates');
		const selected = templates.find((t) => t._id === value);
		setTemplate(selected);
	};

	return template ? (
		<p className="text-sm">
			{template.name}
			<span className="text-xs capitalize">{` (${template.template_type})`}</span>
		</p>
	) : (
		<RenderNA />
	);
};
